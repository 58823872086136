import React, { useState } from 'react';
import { Link } from 'gatsby';
import logo from '../img/logo.jpg';

import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img
              src={logo}
              alt="logo"
              style={{ width: '52px', minHeight: '52px' }}
            />
          </Link>
          {/* Hamburger menu */}
          <button
            className={`navbar-burger burger ${isActive && 'is-active'}`}
            aria-expanded={isActive}
            onClick={() => setIsActive(!isActive)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <ul
          id="navMenu"
          className={` navbar-start has-text-centered navbar-menu ${
            isActive && 'is-active'
          }`}
        >
          {/* TODO: inline override of padding is a result of refactoring
                to a ul for accessibilty purposes, would like to see a css
                re-write that makes this unneccesary.
             */}
          <li className="navbar-item" style={{ padding: '0px' }}>
            <Link className="navbar-item" to="/omnie">
              O mnie
            </Link>
          </li>
          <li className="navbar-item" style={{ padding: '0px' }}>
            <Link className="navbar-item" to="/oferta">
              Oferta
            </Link>
          </li>
          <li className="navbar-item" style={{ padding: '0px' }}>
            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
          </li>
          <li className="navbar-item" style={{ padding: '0px' }}>
            <Link className="navbar-item" to="/kontakt">
              Kontakt
            </Link>
          </li>
          <li className="navbar-item">
            <a className="is-bold" href="tel:504220270">
              tel ☎️ 504 220 270
            </a>
          </li>
          <li className="navbar-item">
            <a className="is-bold" href="mailto:iwona.urbanskaa@gmail.com">
              📧 iwona.urbanskaa@gmail.com
            </a>
          </li>
        </ul>
        <div className="column is-4 social">
          <a
            title="facebook"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Trener.Kwantowy"
          >
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
          <a
            title="instagram"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/trener_kwantowy/"
          >
            <img
              src={instagram}
              alt="Instagram"
              style={{ width: '1em', height: '1em' }}
            />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
