import * as React from 'react';
import { Link } from 'gatsby';

import logo from '../img/logo.jpg';
import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
// import twitter from '../img/social/twitter.svg';
// import vimeo from '../img/social/vimeo.svg';

const Footer = () => {
  return (
    <footer className="footer has-background-black has-text-white-ter">
      <div className="content has-text-centered">
        <img src={logo} alt="logo" style={{ width: '14em', height: '14em' }} />
      </div>
      <div className="content has-text-centered has-background-black has-text-white-ter">
        <div className="container has-background-black has-text-white-ter">
          <div style={{ maxWidth: '100vw' }} className="columns">
            <div className="column is-4">
              <section className="menu">
                <ul className="menu-list">
                  <li>
                    <Link to="/" className="navbar-item">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/omnie">
                      O mnie
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/oferta">
                      Oferta
                    </Link>
                  </li>
                </ul>
              </section>
            </div>
            <div className="column is-4">
              <section>
                <ul className="menu-list">
                  <li>
                    <Link className="navbar-item" to="/blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/kontakt">
                      Kontakt
                    </Link>
                  </li>
                  <li className="navbar-item">
                    <a className="is-bold" href="tel:504220270">
                      tel ☎️ 504 220 270
                    </a>
                  </li>
                  <li className="navbar-item">
                    <a
                      className="is-bold"
                      href="mailto:iwona.urbanskaa@gmail.com"
                    >
                      📧 iwona.urbanskaa@gmail.com
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="column is-4 social">
              <a
                title="facebook"
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Trener.Kwantowy"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  style={{ width: '1em', height: '1em' }}
                />
              </a>
              <a
                title="instagram"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/trener_kwantowy/"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: '1em', height: '1em' }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
